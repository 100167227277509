// 导出页面为PDF格式,宽度大于高度

import { Loading } from 'element-ui'
import html2Canvas from 'html2canvas'
import JsPdf from 'jspdf'
// import { imgEncode } from '@/api/personOrganize'
// const [A4_WIDTH, A4_HEIGHT] = [595, 842]
const [A4_WIDTH, A4_HEIGHT] = [595, 842]

async function sleep(payload) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, payload)
  })
}

// 重绘精细图像
function scaleCanvas({ width, height, img, scale, cb }) {
  const canvas = document.createElement('canvas')
  canvas.width = width * scale
  canvas.height = height * scale
  const context = canvas.getContext('2d')
  context.scale(scale, scale)
  context.drawImage(img, 0, 0)
  const canvasData = canvas.toDataURL('image/jpeg')
  cb(canvasData)
  return canvasData
}

// 转换成图像
async function toImage(params, pdf, position) {
  const img = new Image()
  if (params instanceof Node) {
    const width = params.offsetWidth
    const height = params.offsetHeight
    let pic = await html2Canvas(params, {
      scale: 1.0 // 提升画面质量，但是会增加文件大小
    })
    pic = pic.toDataURL('image/jpeg')
    const scale = 5 // 清晰度
    // svg转jpeg
    img.setAttribute('src', pic)
    // img.crossOrigin = ''
    const imgHeight = (A4_WIDTH / width) * height
    // 输出分页
    const cb = canvasData => {
      let actualPosition = position
      if (position + imgHeight > A4_HEIGHT) {
        pdf.addPage()
        actualPosition = 0 // 分页后页眉
      }
      pdf.addImage(canvasData, 'JPEG', 0, actualPosition, A4_WIDTH, imgHeight)
    }
    img.onload = () => scaleCanvas({ width, height, scale, img, cb })
    return { width, height: imgHeight }
  } else {
    console.error('params is not Node', params)
    throw new Error('params not correct~')
  }
}

// 分割页面
async function splitPage({ list, pdf }) {
  if (!list || !pdf) {
    throw new Error('argument not correct!')
  }
  let position = 0
  for (const item of list.values()) {
    const res = await toImage(item, pdf, position)
    position += res.height
    if (position > A4_HEIGHT) {
      position = res.height
    }
  }
  return true
}

export default function (domName, fileName, call, ctxName = '.m-tab-echarts') {
  window.loading = Loading.service({
    lock: true,
    text: '导出中......', //可以自定义文字
    spinner: 'el-icon-loading', //自定义加载图标类名
    background: 'rgba(0, 0, 0, 0.7)' //遮罩层背景色
  })
  const dom = document.querySelector(domName)
  const pdf = new JsPdf('', 'pt', 'a4')
  const list = dom.querySelectorAll(ctxName)
  splitPage({ list, pdf }).then(result => {
    if (result) {
      sleep(0).then(() => {
        pdf.save(`${fileName}.pdf`)
        window.loading.close()
        //新标签页打开文件
        // const link = document.createElement('a')
        // link.style.display = 'none'
        // link.target = '_blank'
        // link.href = pdf.output('bloburl')
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link)
        // call()
      })
    }
  })
}
